.banner {
    padding: 0;
    margin:0;
    position: relative;
    overflow: hidden;
}

.banner video {
    padding: 0;
    margin:0;
    object-fit: cover;
    /* height: 300px; */
    width: 100%;
}