.icon {
    height: 3.5em;
    width: 3.5em;
}

.list {
    margin: 0;
    padding: 0;
}

.list li {
    list-style-type: none;
    padding-top: 7px;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}