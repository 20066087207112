@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
html {
    margin: 0;
    padding: 0;
    background-color: rgb(255, 255, 255);
    font-family: Roboto, sans-serif;
    overflow-x:hidden;
}

body {
    font-family: Roboto, sans-serif;
    margin: 0;
    padding: 0;
    overflow-x:hidden;
}

.cstm-primary {
    color: rgb(99, 63, 48);
}
.cbg-primary {
    background-color: #efefef;
}

.cstm-secondary {
    color: rgb(140, 13, 13);
}
.cbg-secondary {
    background-color: rgb(140, 13, 13);
}

.cstm-tertiary {
    color: rgb(31, 79, 42);
}
.cbg-tertiary {
    background-color: rgb(31, 79, 42);
}

.cstm-text {
    color: rgb(65, 63, 63);
}
.cbg-text {
    background-color: rgb(65, 63, 63);
}