

.img {
    height: 22em;
    width: 32em;
    padding: 5px;
}

@media (max-width: 800px) {
    .img {
        height: 20em;
        width: 20em;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
    }
}

.btn {
    background-color: rgba(255, 0, 0, 0) !important;
    color: rgb(65, 63, 63) !important;
    border-color: rgb(65, 63, 63) !important;
}

.btn:hover {
    background-color: rgb(65, 63, 63) !important;
    color: aliceblue !important;
}
.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}